import { makeStyles, Button, Box } from '@material-ui/core';

const useStyles = makeStyles({
  image: {
    borderRadius: '20px',
    width: '120px',
    height: '120px',
    cursor: 'pointer',
    boxShadow: '3px 3px 3px #111',
  },
  hashtag: {
    textTransform: 'none',
  },
});

interface CategoryItemProps {
  name: string;
  imageUrl: string;
  onClick: () => void;
}

export default function CategoryItem({
  name,
  imageUrl,
  onClick,
}: CategoryItemProps) {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      mx="auto"
      marginTop={2}
      marginLeft={2.5}
      marginRight={2.5}
      boxShadow={4}
      padding={1}
    >
      <img src={imageUrl} className={classes.image} alt={name} title={name} />
      <Button variant="text" color="primary" className={classes.hashtag}>
        #{name}
      </Button>
    </Box>
  );
}
