import {makeStyles, Button, Box} from '@material-ui/core';
import {formatShortCount} from '@/common/utils/common';
import React from 'react';

const useStyles = makeStyles(theme => ({
  hashtag: {
    textTransform: 'none',
    // backgroundColor: theme.palette.grey.A700,
  },
  tooltip: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: 'bold',
    padding: '3px',
    borderRadius: '4px',
    marginLeft: '8px',
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      position: 'absolute',
      borderTopWidth: '6px',
      borderTopStyle: 'solid',
      borderTopColor: 'transparent',
      borderBottomWidth: '6px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'transparent',
      borderRightWidth: '6px',
      borderRightStyle: 'solid',
      borderRightColor: theme.palette.primary.main,
      left: '-6px',
      top: '4px',
    },
  },
}));

interface HashtagBoxProps {
  name: string;
  totalListings?: number;
  onClick?: () => void;
}

export default function HashtagBox({
  name,
  totalListings,
  onClick,
}: HashtagBoxProps) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Button
        variant="outlined"
        color="primary"
        size="small"
        className={classes.hashtag}
        onClick={onClick}
      >
        #{name}
      </Button>
      {totalListings !== undefined && totalListings > 0 && (
        <div className={classes.tooltip}>
          {formatShortCount(Math.round(totalListings), '')}
        </div>
      )}
    </Box>
  );
}
