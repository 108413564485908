import React from 'react';
import {Typography, Box, makeStyles} from '@material-ui/core';
import { useGlobalContext } from '@/common/contexts/GlobalContext';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px',
    width: '300px',
    height: '300px',
    position: 'relative',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#24262D',
    zIndex: 1,
  },
  subtitle: {
    marginBottom: '8px',
    fontWeight: 'bold',
  },
  description: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    width: '75%',
  },
  image: {
    width: '80px',
  },
  textContainer: {
    marginTop: '75px',
  },
  imageContainer: {
    width: '140px',
    height: '140px',
    borderRadius: '50%',
    position: 'absolute',
    top: '-10px',
    right: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function HeroBanner() {
  const classes = useStyles();
  const {siteName, siteLogoURL, siteTagline, siteTaglineShort} = useGlobalContext()

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginTop={2}
      marginBottom={2}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        className={classes.container}
        boxShadow={4}
      >
        <div className={classes.imageContainer}>
          <img className={classes.image} src={siteLogoURL} />
        </div>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          className={classes.textContainer}
        >
          <Typography className={classes.title}>{siteName}</Typography>
          <Typography className={classes.subtitle}>
            {siteTaglineShort}
          </Typography>
          <Typography color="primary" className={classes.description}>
            {siteTagline}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
